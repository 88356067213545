import { ROUTES } from '@/controllers/router/router.contants';
import { RouterParams } from '@/controllers/router/router.typedefs';

export const singleApplication = (
  courseUserId: number | string,
  activeTab: string,
): RouterParams => ({
  href: `${ROUTES.user.learn}${ROUTES.learn.application}/${courseUserId}/${activeTab}`,
});

export const newApplication: RouterParams = {
  href: `${ROUTES.user.learn}${ROUTES.learn.application}/${ROUTES.applicationSurvey.new.query}`,
};
